import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { baseUrl } from 'constants/baseUrl';

// Default values based on API specs
const defaultCleanSettings = {
  criteria: {
    organization: 'all',
    clients: 'all',
    brands: 'all',
    location: ['all'],
    sort_by: 'title',
    sort_order: 'desc',
  },
};

// Default values for settings and form data
const defaultSettings = {
  exampleSettingGroup: {
    organization: 'all',
    clients: 'all',
    brands: 'all',
    location: ['all'],
    sort_by: 'title',
    sort_order: 'desc',
  },
};

const defaultFormData = {
  exampleSettingGroup: {
    organization: {
      type: 'select',
      options: ['all', 'specific_org1', 'specific_org2'],
      desc: 'Filter jobs by organization. Default: all.',
    },
    clients: {
      type: 'select',
      options: ['all', 'client1', 'client2'],
      desc: 'Filter jobs by clients. Default: all.',
    },
    brands: {
      type: 'select',
      options: ['all', 'brand1', 'brand2'],
      desc: 'Filter jobs by brands. Default: all.',
    },
    location: {
      type: 'select',
      options: ['all', 'location1', 'location2'],
      multiple: true,
      desc: 'Filter jobs by location. Default: all.',
    },
    sort_by: {
      type: 'select',
      options: ['title', 'date', 'salary'],
      desc: 'Sort jobs by attribute. Default: title.',
    },
    sort_order: {
      type: 'select',
      options: ['asc', 'desc'],
      desc: 'Sort order (asc or desc). Default: desc.',
    },
  },
};

// replace http:// or https:// with empty string
const noHttpsBaseUrl = baseUrl.replace(/(^\w+:|^)\/\//, '');

const JobsEmbedPage = ({
  cleanSettings = defaultCleanSettings,
  settings = defaultSettings
}) => {
  const [formData, setFormData] = useState(defaultFormData);
  const [formValues, setFormValues] = useState(settings);
  const [isLoading, setIsLoading] = useState(true);
  const [currentScript, setCurrentScript] = useState(`
    HLSettings = ${JSON.stringify(cleanSettings)};
    (function(w, d, s) {
      var js, fjs = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.src = "//${noHttpsBaseUrl}/embed";
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
    })(window, document, "script");
  `);

  const handleInputChange = (key, label, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: {
        ...prevValues[key],
        [label]: Array.isArray(value) ? value.map(option => option.value) : value, 
      },
    }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/rest/jobs-embed`);

      // Extract criteria from the API response
      const criteria = response.data.data.formData.criteria;

      // Replace keys in defaultFormData with keys from criteria
      const updatedFormData = {
        ...defaultFormData,
        exampleSettingGroup: {
          ...defaultFormData.exampleSettingGroup,
          ...criteria, // Override specific keys (brands, clients, location, organization)
        },
      };

      setFormData(updatedFormData); // Update formData with API response values
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Update the defaultCleanSettings with the new form values
    const updatedSettings = {
      criteria: {
        ...formValues.exampleSettingGroup,
      },
    };

    // Generate the updated script based on new settings
    const updatedScript = `
      HLSettings = ${JSON.stringify(updatedSettings)};
      (function(w, d, s) {
        var js, fjs = d.getElementsByTagName(s)[0];
        js = d.createElement(s);
        js.src = "//${noHttpsBaseUrl}/embed";
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
      })(window, document, "script");
    `;


    // Update the current script state to trigger re-render
    setCurrentScript(updatedScript);
  };

  useEffect(() => {
    // Remove the existing script if it exists
    const existingScriptArea = document.getElementById('scriptArea');
    // update the script area with the new script
    if (existingScriptArea) {
      existingScriptArea.value = `
        <div class="row"><div id="hl-container"></div></div><script>${currentScript}</script>
      `;
    }
    // add the script to the page
    const existingScript = document.querySelector(`script[src="//${noHttpsBaseUrl}/embed"]`);
    if (existingScript) {
      existingScript.remove();
    }

    // add the script to the page
    const script = document.createElement('script');
    script.innerHTML = currentScript;
    document.body.appendChild(script);

    // recreate hl-container
    const existingContainer = document.getElementById('hl-container');
    if (existingContainer) {
      existingContainer.remove();
    }
    const container = document.createElement('div');
    container.id = 'hl-container';
    document.querySelector('.embed-code-output-section .card-section').appendChild(container);

    return () => {
      // remove the script from the page
      script.remove();
    };

  }, [currentScript]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="jobs-embed-container">
      {/* Embed Form/Options */}
      <div className="embed-form-section">
        <div className="card" style={{ width: '100%', padding: '20px' }}>
          <h4 className="card-title">Embed Form/Options</h4>
          <div className="card-section">
            <form onSubmit={handleSubmit}>
              {Object.keys(formValues).map((key) => (
                <div key={key} className="form-group">
                  <h5 className="form-header">{key.toUpperCase()}</h5>
                  <div className="grid-x grid-margin-x">
                    {Object.keys(formValues[key]).map((label) => {
                      const formSetting = formData[key] && formData[key][label];
                      const value = formValues[key][label];
                      let inputElement;

                      if (!formSetting) return null;

                      switch (formSetting.type) {
                        case 'text':
                          inputElement = (
                            <input
                              type="text"
                              value={value}
                              onChange={(e) => handleInputChange(key, label, e.target.value)}
                            />
                          );
                          break;
                        case 'select':
                          const optionsArray = Object.entries(formSetting.options).map(([value, label]) => ({ value, label }));

                          inputElement = (
                            <Select
                              isLoading={isLoading}
                              options={optionsArray}
                              onChange={(selected) => 
                                formSetting.multiple  // Check if it's a multi-select
                                  ? handleInputChange('exampleSettingGroup', label, selected) // Pass the array of selected options
                                  : handleInputChange('exampleSettingGroup', label, selected.value) // Pass the single selected value
                              }
                              isMulti={formSetting.multiple}
                            />
                          );
                          break;
                        case 'radio':
                          inputElement = formSetting.options.map((opt) => (
                            <label key={opt}>
                              <input
                                type="radio"
                                name={`${key}.${label}`}
                                value={opt}
                                checked={value === opt}
                                onChange={(e) => handleInputChange(key, label, e.target.value)}
                              />
                              {opt}
                            </label>
                          ));
                          break;
                        default:
                          inputElement = null;
                      }

                      return (
                        <div className="cell small-12 large-6" key={label}>
                          <label>{label}</label>
                          {inputElement}
                          {formSetting.desc && (
                            <div className="form-desc">
                              <p>
                                {formSetting.desc.replace(/<[^>]*>?/gm, '')}
                              </p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
              <button type="submit" className="button submit-button">
                Submit Form
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Embed Code & Output */}
      <div className="embed-code-output-section">
        <div className="card" style={{ width: '100%', padding: '20px' }}>
          <h4 className="card-title">Embed Code</h4>
          <div className="card-section">
            <textarea rows="10" id="scriptArea" readOnly style={{ width: '100%' }}>
              {`<div class="row"><div id="hl-container"></div></div>
<script>
${currentScript}
</script>`}
            </textarea>
          </div>
        </div>

        <div className="card" style={{ width: '100%', padding: '20px' }}>
          <h4 className="card-title">Embed Output</h4>
          <div className="card-section">
            <div className="row">
              <div id="hl-container"></div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .jobs-embed-container {
          display: flex;
          gap: 20px;
          margin: 20px;
        }

        .embed-form-section {
          flex: 1;
          min-width: 300px;
          max-width: 40%;
        }

        .embed-code-output-section {
          flex: 2;
          min-width: 300px;
          max-width: 100%;
        }

        .form-group {
          margin-bottom: 20px;
        }

        .form-header {
          font-weight: bold;
          margin-bottom: 10px;
        }

        .form-desc {
          margin-top: 5px;
          color: #6c757d;
        }

        .submit-button {
          margin-top: 20px;
          float: right;
        }
      `}</style>
    </div>
  );
};

export default JobsEmbedPage;
