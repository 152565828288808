import JobsEmbedPage from 'components/jobsEmbed/JobsEmbedPage';
import React from 'react';

export const JobsEmbedContainer = () => {
  return (
    <div>
      <JobsEmbedPage />
    </div>
  );
};
